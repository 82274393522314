<template>
  <div>
    <a-breadcrumb class="breadcrumb">
      <a-breadcrumb-item>工作台</a-breadcrumb-item>
      <a-breadcrumb-item>扫码</a-breadcrumb-item>
    </a-breadcrumb>
    <div class="main-body scan_box">
      <a-tabs :animated="false" :activeKey="activeKey" @change="changeTab">

        <!-- <a-tab-pane key="1">
          <span slot="tab">学员签到</span>
          <students v-if="activeKey==='1'"/>
        </a-tab-pane> -->

        <!-- <a-tab-pane key="2">
          <span slot="tab">活动签到</span>
          <events v-if="activeKey==='2'"/>
        </a-tab-pane> -->

        <a-tab-pane key="3">
          <span slot="tab">扫码支付</span>
          <payment v-if="activeKey==='3'"/>
        </a-tab-pane>

      </a-tabs>
    </div>
  </div>
</template>

<script>
import students from './students'
import events from './events'
import payment from './payment'
export default {
  components: {
    students,
    payment,
    events
  },
  data() {
    return {
      activeKey: '3'
    }
  },

  methods: {
    changeTab(key) {
      this.activeKey = key
    }
  }
}
</script>

<style lang="scss">
    .scan_box{
        width: 60%;
        padding: 0 15px;
        background: #fff;
    }
</style>    